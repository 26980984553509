<template>
	<v-row no-gutter dense class="authorized-user R-Layout d-flex flex-column">
		<div class="d-flex justify-start pb-10" style="margin-top:-40px;">
			<div v-on:click="changeTab('product')">
				<app-back-btn />
			</div>
		</div>

		<v-row class="ma-0">
			<v-col cols="12" class="col-12">
				<v-card tag="div" elevation="0" outlined color="#F7F9FC">
					<p class="my-heading ma-0" style="padding-bottom:48px">Add authorized users</p>

					<v-row class="alternet d-flex flex-column ma-0">
						<transition-group name="add-remove">
							<v-row
								v-for="user in authorizeusers"
								v-bind:key="user.email"
								class="ma-0 d-flex flex-column add-remove-item"
							>
								<div class="user-box box-white d-flex align-center justify-end PR-32">
									<span class="mr-auto">{{ user.email }}</span>
									<span class="icon">
										<img
											:src="$store.state.icons.icons['Dustbin']"
											@click="deleteUser(user)"
											class="H-18"
											alt
										/>
									</span>
								</div>
							</v-row>
						</transition-group>
					</v-row>

					<v-form v-model="valid" ref="form" lazy-validation>
						<v-row v-if="adduser" class="pt-4">
							<div class="user-box d-flex align-center justify-end PR-32">
								<span class="mr-auto white-text-box">
									<v-text-field
										single-line
										solo
										:hide-details="Show"
										autocomplete="false"
										height="48"
										color="#CACEDA"
										type="text"
										placeholder="Enter user email address"
										:rules="emailRules"
										v-model="email"
										required
									></v-text-field>
								</span>

								<span class="text-button">
									<v-btn class="text-btn" text :disabled="!valid" @click="addUser">Confirm</v-btn>
								</span>
							</div>
						</v-row>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
		<v-row
			dense
			v-if="$store.state.auth.error != ''"
			class="align-center justify-center p-0 custom-error"
		>
			<v-col cols="12">{{ this.$store.state.auth.error }}</v-col>
		</v-row>
		<v-row class="ma-0">
			<v-col class="d-flex flex-sm-row flex-column align-self-end justify-end pb-0" cols="12">
				<v-btn
					class="my-outline-btn mr-sm-auto mr-sm-5 mt-sm-0 mt-3"
					depressed
					outlined
					v-on:click="adduser = !adduser"
				>Add user</v-btn>

				<v-btn class="my-outline-btn mr-sm-5 mt-sm-0 mt-3" depressed outlined @click="skipSettings">Skip settings</v-btn>

				<v-btn class="my-p-btn my_elevation mt-sm-0 mt-3" id="my_elevation" depressed @click="next">Next</v-btn>
			</v-col>
		</v-row>
	</v-row>
</template>

<script>
import { AXIOS } from "../../plugins/axios";
import Cookies from "js-cookie";
require("../../scss/authorize.scss");
import {
	setUser,
	addAuthorizeUser,
	removeAuthorizeUser
} from "../../services/registerservice";

export default {
	data() {
		return {
			show: true,
			valid: true,
			error_message: "",
			company_id: "",
			show_message: "none",
			adduser: false,
			authorizeusers: [],
			email: "",
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+/.test(v) || "E-mail must be valid"
			]
		};
	},

	async created() {
		if (this.$store.state.register.register_user.user_id === "") {
			await setUser();
			this.myAuthorize();
		} else {
			this.myAuthorize();
		}
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		},
		ShowMessage() {
			return (this.show_message = !this.error_message ? "none" : "block");
		}
	},

	methods: {
		myAuthorize() {
			let company = JSON.parse(
				JSON.stringify(this.$store.state.register.company)
			);

			let authorize_user = JSON.parse(
				JSON.stringify(
					this.$store.state.register.authorize_user.authorize_user
				)
			);

			this.company_id = company.company_id;

			authorize_user.forEach(user => {
				let obj = {
					user_id: user.user_id,
					email: user.email
				};

				this.authorizeusers.push(obj);
			});
		},

		async addUser() {
			if (this.$refs.form.validate()) {
				this.adduser = false;
				this.$store.commit("errorMessage", "");
				let data = await addAuthorizeUser(this.company_id, {
					email: this.email
				});

				if (data.data) {
					this.$store.commit("authorizecomplete");
					let obj = {
						user_id: data.data.id,
						email: data.data.email
					};

					this.authorizeusers.push(obj);
					this.$store.state.register.authorize_user.authorize_user = JSON.parse(
						JSON.stringify(this.authorizeusers)
					);
					this.email = "";
				}
			}
		},

		async deleteUser(user) {
			let data = await removeAuthorizeUser(user.user_id, this.company_id);

			if (data.data) {
				this.authorizeusers.splice(
					this.authorizeusers.indexOf(user),
					1
				);

				this.$store.state.register.authorize_user.authorize_user.splice(
					this.authorizeusers.indexOf(user),
					1
				);
			}
		},

		changeTab(tab_name) {
			this.errorMessages = "";
			this.$store.commit("errorMessage", "");
			this.$store.commit("registerTab", tab_name);
		},

		next() {
			this.changeTab("delivery");
		},

		skipSettings() {
			this.changeTab("delivery");
		}
	}
};
</script>

<style lang="scss">
</style>